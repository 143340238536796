import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AboutUs from './components/AboutUsNew';
import App from './components/App';
import ContactUs from './components/ContactUs';
import Error from './components/Error';
import Extension from './components/Extension';
import ScrollToTop from './components/Helper/ScrollToTop';
import HomeEight from './components/HomeEight';
import Privacy from './components/Privacy';
import Terms from './components/Terms';

function Routes() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 100);
    });
    return (
        <>
            
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <Router>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/" component={HomeEight} />
                            <Route exact path="/smartsearch" component={App} />
                            <Route exact path="/terms" component={Terms} />
                            <Route exact path="/privacy" component={Privacy} />
                            
                            <Route exact path="/aboutUs" component={AboutUs} />
                            <Route exact path="/contact" component={ContactUs} />
                            <Route exact path="/extension" component={Extension} />
                            <Route exact path="/gadgetly" component={Extension} />
                            <Route component={Error} />
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default Routes;
